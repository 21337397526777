import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import PreFooter from '../components/PreFooter';
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { theme } from '../styles/theme';
import { SubSiteWrapper } from '../styles/styleTemplates';

export default ({}) => {
    return (
        <Layout backgroundColor={theme.colors.veryLightPink}>
            <Navigation hideBg={true} />
            <SubSiteWrapper>
                <Row>
                    <Col>
                        <h2>Impressum</h2>
                        <h2>PROJECT FLOORS GmbH</h2>
                        <p>
                            Max-Ernst-Straße 4
                            <br />
                            50354 Hürth
                            <br />
                            <br />T <a href="tel:+490223396870">02233 9687 0</a>
                            <br />F <a href="fax:+4902233968710">02233 9687 10</a>
                            <br />E <a href="mailto:info@project-floors.com">info( at )project-floors.com</a>
                            <br />
                            <br />
                            Geschäftsführer: Bernd Greve, Zoran Stopar
                            <br />
                            USt-ID: DE 812723963
                            <br />
                            Registergericht: Köln, HRB 44604
                            <br />
                            <br />
                            <br />
                            <a href="">Unsere AGB</a>
                        </p>
                        <br />
                        <h2>Konzeption</h2>
                        <p>
                            meet Momentum GmbHbr <br />
                            <a href="https://www.meetmomentum.com">www.meetmomentum.com</a>
                        </p>
                        <br />
                        <h2>Design</h2>
                        <p>
                            rabubble design <br />
                            <a href="https://www.rabeadittmar.de">www.rabeadittmar.de</a>
                        </p>
                        <br />
                        <h2>Datenschutz-beauftragter</h2>
                        <p>
                            PROJECT FLOORS GmbHvr <br />
                            Max-Ernst-Str. 4<br />
                            50354 Hürth
                            <br />
                            datenschutz( at )project-floors.com
                            <br />
                        </p>
                        <br />
                        <h2>
                            Wichtiger Hinweis zu allen Links <br /> auf www.project-floors.com
                        </h2>
                        <p>
                            Das Landgericht Hamburg (LG) hat mit dem Urteil vom 12. Mai 1998 - 312 O 85/98 - entschieden, dass nicht nur die Inhalte einer
                            Website, sondern auch die Inhalte von verlinkten Seiten mit zu verantworten sind. Ausschließen kann man dies nur, indem man sich
                            ausdrücklich von diesen Inhalten distanziert. Hiermit distanzieren wir uns ausdrücklich von allen Inhalten aller verlinkten Seiten
                            auf unserer Website. Diese Erklärung gilt für alle auf unserer Website angebrachten Links. Der Inhalt dieser Website ist
                            urheberrechtlich geschützt. Die Bereitstellung von Inhalten der Website www.project-floors.com auf anderen Websites ist nur mit
                            ausdrücklicher Genehmigung der Geschäftsführung gestattet. Die auf dieser Website enthaltenen Angaben werden nach bestem Wissen
                            erstellt und mit großer Sorgfalt auf ihre Richtigkeit überprüft. Trotzdem sind inhaltliche und sachliche Fehler nicht vollständig
                            auszuschließen. PROJECT FLOORS übernimmt deshalb keinerlei Garantie und Haftung für die Richtigkeit, Aktualität und Vollständigkeit
                            der bereitgestellten Informationen. Alle Angaben sind ohne Gewähr. Dies gilt auch für alle Links zu anderen URLs, die auf unserer
                            Website genannt werden. © 2020 www.project-floors.com, Hürth
                        </p>
                    </Col>
                </Row>
            </SubSiteWrapper>
            <PreFooter />
            <Footer />
        </Layout>
    );
};
